import styled from "styled-components"

export const SeriesButtonStyles = styled.div`
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;

    button {
      text-decoration: none;
      border: none;
      border-radius: 30px;
      margin: 5px;
      padding: 20px;
      width: auto;
      overflow: visible;
      cursor: pointer;
      background: transparent;
      font-weight: 900;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: all .3s ease-in-out;
      &:hover {
          transform: translateY(-7%);
      }
  }
`
