import * as React from "react"
import { Link } from "gatsby"
import Button from "../Button/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ProductStyles } from "./ProductStyles"

const Product = ({ feature }) => {
  const { name, slug, images, excerpt, productCategory, series } = feature
  const image = getImage(images[0])

  return (
    <ProductStyles>
      <div className="product__item">
        <Link to={`/edm/${productCategory}/${slug}`}>
          <GatsbyImage
            className="product__item--img"
            image={image}
            alt="Product thumnail"
          />
          <div className="product__item--content">
            {name && <h2>{name}</h2>}
            {excerpt && <p>{excerpt}</p>}
            <Button text="Read More" as="span" />
          </div>
        </Link>
      </div>
    </ProductStyles>
  )
}

export default Product
