import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ProductCategoryStyles } from "./ProductCategoryStyles"
import { seriesData } from "../../constants/seriesData"

import Product from "./Product"
import SeriesButton from "../SeriesButton/SeriesButton"
import { useCallback } from "react"

const getProducts = graphql`
  query {
    edm: allContentfulEdm {
      edges {
        node {
          name
          series
          productCategory
          excerpt
          contentful_id
          slug
          images {
            gatsbyImageData(width: 600, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`

const ProductCategory = ({ productCategory }) => {
  const response = useStaticQuery(getProducts)
  const products = response.edm.edges

  const seriesList = seriesData.filter(
    (obj) => obj.productCategory === productCategory
  )[0].data

  const allSeries = ["All", ...seriesList.map((obj) => obj.series)]

  const [currentSeries, setCurrentSeries] = useState("All")
  const [buttons, setButtons] = useState(allSeries)

  const seriesFilter = useCallback(
    (button) => {
      if (button === "All") {
        setCurrentSeries("All")
        return
      } else {
        const filteredData = seriesList
          .filter((obj) => obj.series === button)
          .map((item) => item.series)

        setCurrentSeries(filteredData[0])
      }
    },
    [seriesList]
  )

  //   console log test
  useEffect(() => {
    // console.log(products.map(({ node })ㅌ => node.series))
  })

  return (
    <ProductCategoryStyles>
      <SeriesButton buttons={buttons} seriesFilter={seriesFilter} />
      <div className="products__container">
        <div className="products__grid scroll">
          {products
            .filter(({ node }) =>
              node.productCategory === productCategory &&
              currentSeries === "All"
                ? node.productCategory === productCategory //do nothing
                : node.series === currentSeries
            )
            .map(({ node }) => {
              return <Product feature={node} />
            })}
        </div>
      </div>
    </ProductCategoryStyles>
  )
}

export default ProductCategory
