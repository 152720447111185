import styled from "styled-components"

export const ProductCategoryStyles = styled.section`
  transition: all 0.3s ease-in-out;
  color: #000;
  padding: var(--sectionPadding) var(--borderSpacing);

  margin-left: auto;
  margin-right: auto;

  .products__grid {
    display: grid;
    /* this one line of code makes grid responsive */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 3em;

    /* @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    } */
  }
`
