import styled from "styled-components"

export const ProductStyles = styled.div`
  transition: all 0.3s ease-in-out;

  .product__item {
    width: 100%;
    height: 330px;
    /* padding-bottom: 100%; */
    overflow: hidden;

    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;

    > a {
      display: flex;
      width: 100%;
      height: 100%;

      align-items: flex-end;
      position: relative;
      text-decoration: none;
    }

    &--img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      transition: transform 0.3s ease;
    }

    &--content {
      width: 100%;
      position: relative;
      z-index: 2;
      padding: 40px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );

      > * {
        &:first-child {
          /* margin-top: 0; */
        }

        &:last-child {
          /* margin-bottom: 0; */
        }
      }

      > h2 {
        color: #fff;
        font-size: var(--h2);
        margin-bottom: 20px;
      }

      > p {
        color: #fff;
        font-size: var(--p);
        margin-bottom: 40px;
      }
    }

    &:hover,
    &:focus {
      cursor: pointer;

      h2,
      p {
        color: #fff;
      }

      .product__item--img {
        transform: scale(1.1);
      }
    }
  }
`
