export const chmerAwardList = [
  { id: 1, text: "1995년 1월 대만 방전기 업체 최초 ISO 9002 획득" },
  {
    id: 2,
    text: "1987년 8월 대만 및 중국산업국에서 수여하는 최고의 품질상 획득",
  },
  { id: 3, text: "국가산업상 8회 수상, 국가선도 기업상 2회 수상" },
  { id: 4, text: "CE MARK 획득" },
  { id: 5, text: "대만국가에서 인증한 WELL MADE MARK 획득" },
  { id: 6, text: "GOLDEN DRAGON상 수여" },
  { id: 7, text: "다수의 대만 공상부에서 수여하는 포장 및 훈장 수여" },
]

export const edmLinks = [
  { path: "/edm/wirecut", text: "와이어컷팅기" },
  { path: "/edm/diesinker", text: "방전기" },
  { path: "/edm/superdrill", text: "슈퍼드릴" },
  { path: "/edm/highspeed", text: "고속가공기" },
]
