import React from "react"
import { SeriesButtonStyles } from "./SeriesButtonStyles"

const SeriesButton = ({ buttons, seriesFilter }) => {
  return (
    <SeriesButtonStyles>
      <div className="buttonContainer">
        {buttons.map((series, i) => (
          <button type="button" onClick={() => seriesFilter(series)} key={i}>
            {series !== "All" ? `${series} 시리즈` : series}
          </button>
        ))}
      </div>
    </SeriesButtonStyles>
  )
}

export default SeriesButton
