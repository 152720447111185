import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { edmLinks } from "../../constants/chmerIntroData"
import { lathesLinks } from "../../constants/feelerintroData"
import { StaticImage } from "gatsby-plugin-image"

const PageLinkStyles = styled.section`
  /* Link Section */
  background: #26262e;
  color: #fff;

  .links__section {
    .links {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          position: relative;
          font-style: italic;
          font-weight: 900;
          padding: 55px 35px;
          border-top: 2px solid #fff;
          transition: all 0.2s ease;

          &:hover {
            padding: 55px 55px;
            background: #daa700;
            color: #000;
          }

          &:last-child {
            border-bottom: 2px solid #fff;
          }

          @media (max-width: 375px) {
            padding: 55px 5px;
          }

          .link {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            padding: 35px;
            font-size: 30px;
            text-decoration: none;
            color: inherit;

            span {
              color: var(--primary);
            }

            @media (max-width: 325px) {
              font-size: 20px;
            }
          }

          .link__icon {
            transform: translate(25%, 100%);
            @media (max-width: 325px) {
              transform: translate(25%, 50%) scale(90%);
            }
          }
        }
      }
    }
  }
`

const PageLinks = ({ brand }) => {
  return (
    <PageLinkStyles>
      <section className="links__section">
        <div className="links">
          <ul>
            {(brand === "feeler" ? lathesLinks : edmLinks).map(
              (item, index) => (
                <li key={index} className="link__list">
                  <Link to={item.path} className="link">
                    {item.text}
                    <span>.</span>
                    <StaticImage
                      src="../../images/icons/arrow01.png"
                      layout="constrained"
                      placeholder="tracedSVG"
                      className="link__icon"
                    />
                  </Link>
                </li>
              )
            )}
          </ul>
        </div>
      </section>
    </PageLinkStyles>
  )
}

export default PageLinks
