export const feelerProduction = [
  { id: 1, text: "년간 수직형 머시닝 센터 생산 → 전세계 1위" },
  {
    id: 2,
    text: "800 ~ 1000 mm X축 수직형 머시닝 센터 년간 단일 모델생산 → 전세계 1위",
  },
  { id: 3, text: "전 기능 머시닝센터 생산 → 대만 및 중국내 생산 1위" },
]

export const lathesLinks = [
  { path: "/lathes/machining", text: "머시닝센터" },
  { path: "/lathes/speedmachine", text: "고속가공기" },
]
