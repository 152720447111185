export const seriesData = [
  // edm
  {
    productCategory: "wirecut",
    data: [
      {
        name: "G 시리즈",
        series: "G",
      },
      {
        name: "GX+ 시리즈",
        series: "GX+",
      },
      {
        name: "NV 시리즈",
        series: "NV",
      },
      {
        name: "RV 시리즈",
        series: "RV",
      },
      {
        name: "RX 시리즈",
        series: "RX",
      },
      {
        name: "RQ 시리즈",
        series: "RQ",
      },
    ],
  },

  {
    productCategory: "diesinker",
    data: [
      {
        name: "CNC 시리즈",
        series: "CNC",
      },
      {
        name: "CNC A 시리즈",
        series: "CNC A",
      },
      {
        name: "CNC 리니어",
        series: "CNC Linear",
      },
      {
        name: "EZ",
        series: "EZ",
      },
      {
        name: "MP 시리즈",
        series: "MP",
      },
    ],
  },

  {
    productCategory: "superdrill",
    data: [
      {
        name: "CNC 시리즈",
        series: "DRILL-CNC",
      },
    ],
  },

  {
    productCategory: "highspeed",
    data: [
      {
        name: "HE 시리즈",
        series: "HE",
      },
      {
        name: "HM 시리즈 (리니어모터)",
        series: "HM",
      },
    ],
  },

  // lathes
  {
    productCategory: "machining",
    data: [
      {
        name: "FTC 시리즈",
        series: "FTC",
      },
      {
        name: "FMH 시리즈",
        series: "FMH",
      },
    ],
  },

  {
    productCategory: "speedmachine",
    data: [
      {
        name: "QMP 시리즈",
        series: "QMP",
      },
    ],
  },
]
